(function ($) {
  $(document).ready(function () {
    var portfolio = $("#owl-carousel-portfolio");
    portfolio.owlCarousel({
      margin: 10,
      loop: true,
      autoWidth: true,
      items: 3,
      nav: true,
      center: true,
      // navRewind: false,
      responsiveClass: true,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        738: {
          items: 3,
          nav: true,
        },
      },
      onTranslated: callBackPortfoilo,
    });

    function callBackPortfoilo() {
      if ($("#owl-carousel-portfolio .owl-item").last().hasClass("active")) {
        $(".customNextBtn").removeClass("activePortfolio");
        $(".customPrevBtn").addClass("activePortfolio");
        console.log("true");
      } else if (
        $("#owl-carousel-portfolio .owl-item").first().hasClass("active")
      ) {
        $(".customPrevBtn").removeClass("activePortfolio");
        $(".customNextBtn").addClass("activePortfolio");
        console.log("false");
      }
    }
    $(".customNextBtn").addClass("activePortfolio");

    $(".customNextBtn").click(function () {
      portfolio.trigger("next.owl.carousel");
    });
    // Go to the previous item
    $(".customPrevBtn").click(function () {
      portfolio.trigger("prev.owl.carousel", [300]);
    });

    var team = $("#owl-carousel-team");
    team.owlCarousel({
      margin: 50,
      loop: true,
      autoWidth: true,
      nav: true,
      center: true,
      items: 6,
      responsiveClass: true,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        738: {
          items: 4,
          nav: true,
        },
      },
      onTranslated: callBack,
    });

    function callBack() {
      if ($("#owl-carousel-team .owl-item").last().hasClass("active")) {
        $(".customNextTeamBtn").removeClass("activeTeam");
        $(".customPrevTeamBtn").addClass("activeTeam");
        console.log("true");
      } else if ($("#owl-carousel-team .owl-item").first().hasClass("active")) {
        $(".customPrevTeamBtn").removeClass("activeTeam");
        $(".customNextTeamBtn").addClass("activeTeam");
        console.log("false");
      }
    }
    $(".customNextTeamBtn").addClass("activeTeam");
    var story = $("#owl-carousel-story");
    story.owlCarousel({
      margin: 10,
      loop: true,
      autoWidth: true,
      // navRewind: false,
      items: 2,
      nav: true,
      center: true,
      responsiveClass: true,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        738: {
          items: 2,
          nav: true,
        },
      },
      // nav:true,
      onTranslated: callBackStory,
    });

    function callBackStory() {
      if ($("#owl-carousel-story .owl-item").last().hasClass("active")) {
        $(".customNextClientBtn").removeClass("activeClient");
        $(".customPrevClientBtn").addClass("activeClient");
        console.log("true");
      } else if (
        $("#owl-carousel-story .owl-item").first().hasClass("active")
      ) {
        $(".customPrevClientBtn").removeClass("activeClient");
        $(".customNextClientBtn").addClass("activeClient");
        console.log("false");
      }
    }

    $(".customNextClientBtn").addClass("activeClient");
    $(".customNextTeamBtn").click(function () {
      team.trigger("next.owl.carousel");
    });
    // Go to the previous item
    $(".customPrevTeamBtn").click(function () {
      team.trigger("prev.owl.carousel", [300]);
    });

    $(".customNextClientBtn").click(function () {
      story.trigger("next.owl.carousel");
    });
    // Go to the previous item
    $(".customPrevClientBtn").click(function () {
      story.trigger("prev.owl.carousel", [300]);
    });
  });
})(jQuery);
